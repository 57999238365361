
let origin = window.location.origin;
export const DOMAIN = origin && origin.includes('localhost') ? 'https://testnet2.pirshropay.org' : origin;

export const PATH = {
    DEPOSITS_LIST: '/panel/wallet/deposit',
    WITHDRAWS_LIST: '/panel/wallet/withdraw',
    TRANSACTIONS_LIST: '/panel/wallet/transactions',
    TRANSFERS_LIST: '/panel/exchange/transactions',
    NEW_EXCHANGE: '/panel/exchange/shopping',
    DASHBOARD: '/panel/dashboard',
    HOMEPAGE: '/home',

}