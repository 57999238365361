import React from "react";
import "../../../styles/factorPrint/factorPrint.css"
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../util/IntlMessages";
import {connect} from "react-redux";

class FactorPrint extends React.Component{
    render() {
        return(
            <div className="layout">
                <div className="factor-head">
                    <div className="factor-logo" >
                        <img  className="w-100 d-inline-block"
                        width="113" height="30" alt="pirshropay"
                        src={require("../../../assets/images/Novinbit.png")}/>
                    </div>
                    <div className="d-flex ai-center gap-x-2">
                      <Button variant="contained" color="secondary" size="small">
                          <IntlMessages id="button.factor.print_or_save"/>
                      </Button>
                    </div>
                </div>
                <div className="page">
                    <h1 style={{textAlign: 'center'}}>
                        صورت حساب الکترونیکی
                        فروش نیابتی
                    </h1>
                    <table className="header-table" style={{width: '100%'}}>
                        <tbody>
                        <tr>
                            <td style={{width: '1.8cm', height: '2.5cm',verticalAlign: 'middle',paddingBottom: '4px'}}>
                                <div className="header-item-wrapper">
                                    <div className="portait" style={{margin: '5px'}}>معامله گر طرف اول</div>
                                </div>
                            </td>
                            <td style={{padding: '0 4px 4px',height: '2cm'}}>
                                <div className="bordered grow header-item-data">
                                    <table className="grow centered">
                                        <tbody>
                                        <tr>
                                            <td style={{width: '7cm'}}>
                                                <span className="label">فروشنده:</span> شركت نوین ایده پرداز (سهامی خاص)
                                            </td>
                                            <td style={{width: '5cm'}}>
                                                <span className="label">شناسه ملی:</span> ۱۰۳۲۰۸۴۵۸۵۷
                                            </td>
                                            <td>
                                                <span className="label">شماره ثبت:</span> ۴۳۳۸۴۵
                                            </td>
                                            <td>
                                                <span className="label">شماره اقتصادی:</span> ۴۱۱۴۱۹۱۳۶۵۱۱
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <span className="label">نشانی شرکت:</span>
                                                تبریز، اول خ. ارک جدید از طرف مصلی،
                                                ساختمان آراز، طبقه دوم, پلاک ۱, واحد ۲
                                            </td>
                                            <td colSpan="2">
                                                <span className="label"> تلفن و فکس:</span>
                                            </td>
                                            <td colSpan="2">
                                                <span className="label">کد پستی:</span> ۵۱۳۷۷۷۳۵۷۴
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <td style={{width: '7cm', height: '2cm', padding: '0 4px 4px 0'}}>
                                <div className="bordered" style={{textAlign: 'center', height: '100%', padding: '0.4cm 0.2cm'}}>
                                    <div className="flex">
                                        <div className="font-small">شماره فاکتور:</div>
                                        <div className="flex-grow" style={{textAlign: 'left'}}>۲۳۶۹۲۲۴۳</div>
                                    </div>
                                    <div className="flex">
                                        <div>تاریخ:</div>
                                        <div className="flex-grow" style={{textAlign: 'left'}}>۱۴۰۰/۱۰/۲۹</div>
                                    </div>
                                    <div className="flex" style={{marginBottom: '4px'}}>
                                        <div>پیگیری:</div>
                                        <div className="flex-grow font-medium" style={{textAlign: 'left'}}>۱۴۳۷۹۷۷۹۲</div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{width: '1.8cm', height: '2cm',verticalAlign: 'center', padding: '0 0 4px'}}>
                                <div className="header-item-wrapper">
                                    <div className="portait" style={{margin: '20px'}}>معامله گر طرف ثانی</div>
                                </div>
                            </td>
                            <td style={{height: '2cm',verticalAlign: 'center', padding:'0 4px 4px'}}>
                                <div className="bordered header-item-data">
                                    <table style={{height: '100%'}} className="centered">
                                        <tbody>
                                        <tr>
                                            <td style={{width: '6.7cm'}}>
                                                <span className="label">خریدار:</span> امیر فر
                                            </td>
                                            <td style={{width: '6.7cm'}}>
                                                <span className="label">شماره‌اقتصادی / شماره‌ملی:</span> ۱۳۶۲۲۸۱۴۰۹
                                            </td>
                                            <td>
                                                <span className="label">شناسه ملی:</span> ۱۳۶۲۲۸۱۴۰۹
                                            </td>
                                            <td>
                                                <span className="label">شماره ثبت:</span> -
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="4">
                                                <span className="label">نشانی:</span>
                                                تبریز، اول خ. ارک جدید از طرف مصلی،
                                                ساختمان آراز، طبقه دوم, پلاک ۱, واحد ۲
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2">
                                                <span className="label">شماره تماس:</span>
                                            </td>
                                            <td colSpan="2">
                                                <span className="label">کد پستی:</span> ۵۱۳۷۷۷۳۵۷۴
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <td style={{width: '7cm',padding: '0 4px 4px 0'}}>
                                <div className="bordered" style={{textAlign: 'center', height:' 100%'}}>
                                    <div className="flex" style={{marginBottom: '4px'}}>
                                        <div>شماره مالیاتی:</div>
                                        <div className="flex-grow font-medium"
                                             style={{textAlign: 'left'}}>KGWNG۰۴A۴۳۱۶۹۸۳D۳۰۷
                                        </div>
                                    </div>
                                    <div className="flex" style={{marginBottom: '4px'}}>
                                        <div>سریال حافظه مالیاتی:</div>
                                        <div className="flex-grow font-medium" style={{textAlign: 'left'}}>S۰۵۴۰۰۱۰۰۰۰۰۰۱۰۰
                                        </div>
                                    </div>
                                    <div className="flex" style={{marginBottom: '4px'}}>
                                        <div>سریال پایانه فروشگاهی:</div>
                                        <div className="flex-grow font-medium" style={{textAlign: 'left'}}>A۰۵۴۰۰۱۰۰۰۰۰۰۱۹۰
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table className="content-table factor-table">
                        <thead>
                        <tr>
                            <th>ردیف</th>
                            <th>شناسه کالا یا خدمت</th>
                            <th style={{width: '30%'}}>شرح کالا یا خدمت</th>
                            <th>آمر</th>
                            <th>تعداد</th>
                            <th style={{width: '2.3cm'}}>مبلغ واحد (ریال)</th>
                            <th style={{width: '2.3cm'}}>مبلغ کل (ریال)</th>
                            <th style={{width: '2.3cm'}}>تخفیف (ریال)</th>
                            <th style={{width: '2.3cm'}}>مبلغ کل پس از تخفیف (ریال)</th>
                            <th style={{width: '2.3cm'}}> جمع مالیات و عوارض ارزش افزوده (ریال)</th>
                            <th style={{width: '2.5cm'}}> جمع کل پس از تخفیف و مالیات و عوارض (ریال)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>۱</td>
                            <td>۱۸۲۶۷۲۴۹</td>
                            <td>
                                <div className="title">مکمل سوخت و اکتان خودرو فستر مدل M-1019 حجم&nbsp;300 میلی
                                    لیتر&nbsp; بسته 3 عددی | گارانتی اصالت و سلامت فیزیکی کالا
                                </div>
                                <div className="serials">4E77E85A</div>
                            </td>
                            <td><span>نوین سوخت</span></td>
                            <td><span className="ltr">۱</span></td>
                            <td><span className="ltr">
                                            ۸۴۰,۰۰۰
                                    </span></td>

                            <td><span className="ltr">
                                            ۸۴۰,۰۰۰
                                        </span></td>

                            <td><span className="ltr">
                                            ۰
                                        </span></td>

                            <td><span className="ltr">
                                            ۸۴۰,۰۰۰
                                        </span></td>

                            <td><span className="ltr">
                                            ۰
                                        </span></td>

                            <td><span className="ltr">
                                        ۸۴۰,۰۰۰
                                        </span></td>

                        </tr>

                        <tr>
                            <td colSpan="4">
                                <b>جمع کل</b>
                            </td>
                            <td>۴</td>
                            <td><span className="ltr">۱,۷۱۰,۰۰۰</span></td>
                            <td><span className="ltr">۲,۰۶۰,۰۰۰</span></td>
                            <td>
                                <span className="ltr">۰</span>
                            </td>
                            <td>
                    <span className="ltr">
                                                ۲,۰۶۰,۰۰۰
                                            </span>
                            </td>
                            <td><span className="ltr">۰</span></td>
                            <td>
                    <span className="ltr">
                                                ۲,۰۶۰,۰۰۰
                                            </span>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan="6">
                            </td>
                            <td colSpan="4" className="font-small">
                                جمع کل پس از کسر تخفیف با احتساب مالیات و عوارض (ریال):
                            </td>

                            <td><span className="ltr">
                                    ۲,۰۶۰,۰۰۰
                                </span></td>
                        </tr>
                        <tr>
                            <td colSpan="4">
                                اعتبار مالیاتی قابل استفاده توسط خریدار
                            </td>
                            <td colSpan="7" className="font-small">
                            </td>
                        </tr>
                        <tr style={{background: '#fff'}}>
                            <td colSpan="11" style={{height: '2.5cm',verticalAlign: 'top'}}>
                                <div className="flex">
                                    <div className="flex-grow">مهر و امضای فروشنده:</div>
                                    <div className="flex-grow">تاریخ تحویل:</div>
                                    <div className="flex-grow">ساعت تحویل:</div>
                                    <div className="flex-grow">روش‌های پرداخت:</div>
                                    <div className="flex-grow">مهر و امضای خریدار:</div>                                <div className="flex-grow" style={{width: '1.8cm'}}></div>
                                </div>
                                <div className="flex">
                                    <div className="flex-grow">
                                        <img alt="alt" className="footer-img uk-align-center" width="150px"
                                             src="https://api.digikala.com/static/files/acb0d08c.jpg"/>
                                    </div>
                                    <div className="flex-grow">۱ بهمن ۱۴۰۰</div>
                                    <div className="flex-grow">۹ -
                                        ۲۲
                                    </div>

                                    <div className="flex-grow">
                                        <ul>
                                            <li style={{textAlign: 'right'}}>
                                                اعتباری : ۲,۳۴۵,۰۰۰
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex-grow"></div>
                                </div>
                                <h3> به هنگام ارسال در سامانه معاملات فصلی ( ماده 169 مکرر) گزینه خرید از طریق حق العمل کار
                                    را انتخاب کنید
                                </h3>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        )
    }
}

const mapStateToProps = ({currency}) => {
    const {selectedOrder} = currency;
    return {selectedOrder}
};
export default connect(mapStateToProps)(FactorPrint);