import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";


class WebsiteRulesDialog extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
           openWebsiteRules: false
        };
    }


    handleRequestClose = () => {
      this.setState({openWebsiteRules: false})
    };

    handleClickOpen = () => {
        this.setState({openWebsiteRules: true});
    };


    render() {
        return (
            <>
                {/*<div className="jr-card-header d-flex align-items-center my-2 ">*/}
                {/*    /!*<Close className="jr-fs-xl text-primary mr-1"/>*!/*/}

                {/*</div>*/}
                <DialogContent>
                    <DialogContentText>

                        <div className="font-weight-bold">قوانین سایت پیشروپی  به شرح زیر به تائید کاربر استفاده کننده سایت می رسد:</div>
                        این متن قرارداد بین سایت اینترنتی پیشروپی  و هر کاربری است به نحوی از انحا از خدمات مختلف سایت استفاده می کند. در این قرارداد حقوق سایت پیشروپی  و شرایط استفاده از آن و حقوق کاربر قید شده است. کاربر سایت پیشروپی  باید این قوانین را به طور کامل بخواند و بفهمد و بپذیرد. این حق برای سایت پیشروپی    محفوظ است که در هر زمان که بخواهد مفاد این قرارداد را تغییر دهد.
                        استفاده از هرگونه امکانات پیشروپی  - ثبت نام - خرید - فروش و هر گونه امکانات دیگر به این معنی است که شما مفاد این قرارداد را کاملا خوانده و فهمیده و قبول کرده اید. اگر با مفاد این قرارداد موافق نیستید حق استفاده از امکانات پیشروپی  را ندارید.
                        طرفین قرارداد سایت اینترنتی پیشروپی  به عنوان دهنده ی خدمات و کاربر سایت به عنوان گیرنده ی خدمات می باشند
                          <div className="font-weight-bold">
                              مفاد قرارداد
                          </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                1.
                            </div>
                            <div>
                                <div className="font-weight-bold">	تعاریف و واژگان:</div>

                                پیشروپی  یک سایت اینترنتی می باشد که فعلا فقط تحت همین یک دامنه از طریق اینترنت قابل دسترس می باشد. این سایت برای کاربران ایرانی تحت قوانین جمهوری اسلامی ایران بوده و از کلیه ی قوانین جمهوری اسلامی ایران تبعیت می کند.
                                <br/>

                                کاربر کسی است که بنابه اراده ی خود از سایت پیشروپی  بازدید می کند و بدون هیچ گونه اجبار از خدمات این سایت استفاده می کند.
                                <br/>
                                ارز الکترونیکی مانند وب مانی  - پی پال یک ابزار مالی بدون بهره است که مورد خرید فروش قرار می گیرد و از آن می توان در سایت های پذیرنده به عنوان یک حواله ی پولی یا ژتون ارزش دار استفاده کرد.
                                <br/>
                                ارز الکترونیکی مورد قبول آنهایی است که در سایت پیشروپی  لیست شده و مورد پذیرش سایت پیشروپی  برای خرید و فروش می باشد.
                                <br/>
                                پرداخت وجه عبارت است از انتقال وجه - ریالی یا دلار - یا به صورت معادل ارز الکترونیکی از پرداخت کننده ی وجه به گیرنده ی وجه.
                                <br/>
                                روش پرداخت عبارت است از هر روشی که برای انتقال وجه در پیشروپی  مجاز شمرده شده است. پرداخت می تواند توسط ارز الکترونیکی یا انتقال بانکی حضوری یا اینترنتی یا توسط کارتهای شتابی از درگاه بانکی پیشروپی  و یا انتقال بین المللی بانکی از طریق سوییفت یا دیگر روش های پرداخت باشد

                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                2.
                            </div>
                            <div>
                                پیشروپی  خدمات زیر را برای کابر فراهم می سازد:
                                <br/>
                                -فروش ارز الکترونیکی به کاربر و دریافت مبلغ معینی وجه پول رایج از وی
                                <br/>
                                -خرید ارز الکترونیکی از کاربر و پرداخت مبلغ معینی وجه رایج به وی
                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                3.
                            </div>
                            <div>
                                با استفاده از خدمات پیشروپی (آنی پیشروپی  خدمات خود را برای هر ارز الکترونیکی که در سایت لیست شده است و ممکن است طبق شرایط مختلف و به دلخواه پیشروپی  تغییر نماید به هر کاربر که بخواهد و منع قانونی نداشته باشد ارائه می کند
                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                4.
                            </div>
                            <div>
                                کاربر می پذیرد که منبع و مقصد کلیه ی ارزهای الکترونیکی و غیر الکترونیکی ارائه شده توسط وی در تراکنش های سایت پیشروپی  کاملا قانونی و مطابق با قوانین بین المللی و مقررات جمهوری اسلامی ایران باشند.
                                <br/>
                                کاربر می پذیرد که مالک قانونی وجوه و حساب هایی است که وی در هر سفارش به هر نحو چه برای پرداخت و چه برای دریافت از آنها استفاده کرده است

                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                5.
                            </div>
                            <div>
                                اطلاعاتی که کاربر در خلال سفارش یا ثبت نام در سایت وارد کرده است کاملا محفوظ خواهد ماند و به هیچ شخص ثالثی ارائه نخواهد شد مگر با نامه ی قضایی و یا درخواست کتبی پلیس محترم فتای جمهوری اسلامی ایران
                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                6.
                            </div>
                            <div>
                                این حق برای پیشروپی  محفوظ است که هر گونه اطلاعات لازم برای احراز هویت را قبل از تکمیل سفارش از کاربر بخواهد تا مطمئن شود که مفاد بند 4 به درستی رعایت شده است. در این حال تا احراز هویت کامل به عمل نیامده است عودت وجوه کاربر امکانپذیر نخواهد بود و چنانچه کاربر نتواند مدارک هویتی لازم خود را از قبیل شماره تلفن ثابت و اسکن کارت ملی یا شناسنامه ارائه نماید و یا مدارک وی دارای خدشه باشند، این حق برای پیشروپی  محفوظ خواهد بود که سفارش کاربر را انجام ندهد و عودت وجه ارسالی وی را موکول به ارسال مدارک کامل نماید.
                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                7.
                            </div>
                            <div>
                                کاربر برای تکمیل یک سفارش اقدام به احراز هویت کامل می نماید و پس از اینکه کاربر اقدام به ارسال مدارک و اطلاعات خود برای تکمیل فرآیند احراز هویت و انجام سفارش نماید ، با این کار وی آگاهانه و با اختیار خود می پذیرد که :
                                <br/>
                                فرآیند احراز هویت شامل گردآوری اطلاعاتی از کاربر من جمله شماره تماس ثابت - آی پی - آدرس - کارت شناسایی با تصویر - کد ملی و اطلاعات بانکی وی می باشد.
                                <br/>
                                این اقدامات حریم شخصی وی را نقض نخواهد کرد و به هیچ شخص ثالثی ارائه نخواهد شد.
                                <br/>
                                تصمیم راجع به موفق یا ناموفق بودن احراز هویت فقط و فقط در اختیار پیشروپی  خواهد بود و سایت پیشروپی  می تواند یک احراز هویت را به تشخیص خود قبول کند یا قبول نکند

                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                8.
                            </div>
                            <div>
                                تراکنش هایی که توسط پیشروپی  در خلال انجام سفارشات انجام می شود غیر قابل بازگشت هستند و تابع قوانین سیستم هر ارز الکترونیکی مربوط به تراکنش انجام شده می باشند. کاربر می پذیرد که اطلاعات لازم در طی سفارش را با دقت وارد کرده است و کلیه ی مسئولیت مربوط به اشتباه وارد کردن اطلاعات در خلال سفارش با خود وی است و پیشروپی  هیچ مسئولیتی راجع به سفارش انجام شده ای که اطلاعات اشتباه داشته و موجب زیان کاربر شده نمی پذیرد
                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                9.
                            </div>
                            <div>
                                ارزهای الکترونیکی و سایر سیستم های پرداخت خود مسئول صحت معاملات و مسئول وجوه کاربران نزد آن سیستم ها می باشند و پیشروپی  هیچ مسئولیتی در قبال صحت کار این سیستم ها به عهده نمی گیرد. چنانچه کاربر هر گونه مشکلی با این سیستم ها داشته باشد باید با خود آن سیستم و تحت قوانین آن سیستم مشکل خود را حل کند و در این بین هیچ گونه مسئولیتی متوجه پیشروپی  نخواهد بود. این سیستم ها در حال حاضر سیستم پرداخت بانک سامان - وبمانی - پی پال و پرداخت های الکترونیکی سامان و ملت را شامل می شوند و ممکن است در آینده تغییراتی داشته باشد. پیشروپی  هیچ گونه مسئولیتی راجع به مشکلات نقل و انتقال بین کاربر و این سیستم ها نمی پذیرد و کاربر بایستی مشکل خود را با خود این سیستم ها حل نماید
                            </div>
                        </div>



                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                10.
                            </div>
                            <div>
                                پیشروپی  یکسان بودن هویت گیرنده و فرستنده را بررسی نمی کند و ممکن است هویت گیرنده با فرستنده در یک تراکنش یکی باشد یا نباشد و پیشروپی  هیچ گونه مسئولیتی راجع به آن نمی پذیرد. . پیشروپی  هیچ گونه مسئولیتی راجع به رابطه ی بین فرستنده با گیرنده نمی پذیرد و هیچ گونه خدمات میانجیگری و حکمیت بین گیرنده و فرستنده در یک تراکنش نمی پذیرد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                11.
                            </div>
                            <div>
                                کاربر سایت پیشروپی  می پذیرد که پیشروپی  صرفا مسئول انتقال مقدار مشخص شده در تراکنش و سفارش با نرخ تبدیل معین است و هیچ گونه مسئولیت دیگری ندارد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                12.
                            </div>
                            <div>
                                خدمات پیشروپی  همانطور که هستند و در سفارش و تراکنش با نرخ مشخص نشان داده شده اند انجام می شوند. مسئولیت هیچ گونه خدمات لفظی - ضمنی - قانونی - تضمینی - نقل قولی دیگر به عهده ی پیشروپی  به هیچ وجه من الوجوه نخواهد بود. پیشروپی  جز انجام سفارش با نرخ معین شده در سفارش هیچ گونه مسئولیت دیگری در قبال کاربر به هیچ نحو و به هیچ صورت ندارد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                13.
                            </div>
                            <div>
                                نرخ تبدیل و قیمت تبدیل ارزهای الکترونیکی مختلف توسط پیشروپی  تعیین می شوند و در روی سایت توسط کلیه ی کابران قابل مشاهده خواهند و در هنگام سفارش نیز مبلغ دریافتی کاربر بعد از تکمیل سفارش با کسر کارمزد ها و با اعمال نرخ تبدیل به طور کامل و شفاف و دقیق به وی نمایانده می شوند
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                14.
                            </div>
                            <div>
                                هر گونه کارمزد - کمیسیون و هزینه ی دیگر که سیستم ارز الکترونیکی به کاربر تحمیل نماید فقط و فقط بر عهده ی کاربر خواهد بود و پیشروپی  هیچ گونه مسئولیتی راجع به این گونه هزینه ها نمی پذیرد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                15.
                            </div>
                            <div>
                                پیشروپی  هیچ گونه مسئولیتی راجع به تاخیر یا تراکنش ناموفق ایجاد شده در انجام سفارش به علت نقص یا مشکل یا تعمیرات سیستم ارز الکترونیکی یا بانک پذیرنده نمی پذیرد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                16.
                            </div>
                            <div>
                                کاربر ایرانی سایت می پذیرد که کلیه ی فعالیت های وی و سایت پیشروپی  تحت قوانین جمهوری اسلامی ایران است و فعالیت های وی در هر صورت هر شکل نباید قوانین جمهوری اسلامی ایران را نقض کند
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                17.
                            </div>
                            <div>
                                حقوق هر کاربر برای استفاده از سایت مخصوص به خود اوست و مسئولیت استفاده ی هر کاربر از نام کاربری و رمز عبور وی فقط و فقط به عهده ی کاربر خواهد بود. کاربر می پذیرد که مسئولیت کلیه ی تراکنش های انجام شده از حساب کاربری وی به عهده ی خود وی است و حق دادن اطلاعات کاربری خود به شخص ثالث برای استفاده از سایت پیشروپی  را ندارد. کاربر می پذیرید که مطالب و کدها و تصاویر و بنرها و لوگو های سایت پیشروپی  مشمول قانون کپی رایت است و کاربر حق استفاده از آنها را بدون اجازه ی کتبی پیشروپی  نخواهد داشت
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                18.
                            </div>
                            <div>
                                هر گونه پیامدهای مالیاتی ناشی از تراکنش های کاربر با پیشروپی  به عهده ی خود کاربر خواهد بود و پیشروپی  هیچ گونه مسئولیتی نمی پذیرد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                19.
                            </div>
                            <div>
                                کاربر می پذیرد که جز در مورد انجام صحیح سفارش طبق قیمت مشخص و شفاف و کارمزد مشخص و شفاف طبق قرارداد فوق هیچ گونه داعیه و طلب و شکایت دیگر عملا و لسانا از سایت پیشروپی  - مدیران - کارمندان - و کلیه ی مرتبطان با این سایت نداشته باشد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                20.
                            </div>
                            <div>
                                کاربر هنگام خرید بواسطه درگاه بانکی از سایت پیشروپی    فقط و فقط حق استفاده از کارت بانکی را دارد که به نام خویش بوده و از این بابت کاربر ملزم است قبل از انجام سفارش شماره کارت بانکی و شماره حساب شبای بانکی خود را به تایید پیشروپی  برساند
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                21.
                            </div>
                            <div>
                                چنانچه کاربر اقدام به خرید با کارت شتابی نماید که مالک آن نیست و این موضوع مشخص گردد حساب کاربری وی به مدت 24 ساعت مسدود خواهد گردید و در صورت تکرار این کار مسدودی همیشگی خواهد بود و مبلغ ریالی سفارش به کارت پرداخت کننده عودت خواهد گردید و در صورت محرز شدن هرگونه سوءاستفاده یا کلاهبرداری، مشخصات هویتی کاربر خاطی به مراجع ذیصلاح ارجاع خواهد شد، همچنین در صورت عودت وجه ریالی کارمزد انتقال وجه به اضافه یک درصد مبلغ سفارش از آن کسر و مابقی عودت می گردد، در هر حال کاربر باید ارقام کامل کارت را به پشتیبانی اعلام کند
                            </div>
                        </div>


                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                22.
                            </div>
                            <div>
                                چنانچه کاربر با کارتی اقدام به خرید نماید که مالک آن است ولی در سایت به تائید نرسانده است باید آن کارت را ثبت کند تا بعد از بررسی سفارش انجام شود، چنانچه تا سی دقیقه بعد از زمان سفارش این کار را انجام ندهد و یا درخواست عودت وجه ریالی نماید کارمزد انتقال وجه به اضافه یک درصد مبلغ سفارش از آن کسر و مابقی عودت می گردد، در هر حال کاربر باید ارقام کامل کارت را به پشتیبانی اعلام کند
                            </div>
                        </div>

                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                23.
                            </div>
                            <div>
                                انجام تراکنش فقط بواسطه کارتهای بانکی ثبت و تایید شده در سایت مجاز بوده و استفاده از کارت بانکی اشخاص دیگر، کارت هدیه و یا بن کارت ممنوع می باشد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                24.
                            </div>
                            <div>
                                کاربر موظف است چنانچه پیشروپی  درخواست ارائه تصویر کارت بانکی را از وی بکند قسمت تاریخ انقضا و کد CVV2 را به نحوی که قابل خواندن نباشد بپوشاند و پیشروپی  هیچ گونه مسئولیتی را در مورد افشا شدن تاریخ انقضا یا کد CVV2 کارت بانکی کاربر برعهده نمی گیرد.
                            </div>
                        </div>
                        <div className="d-inline-flex">

                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                25.
                            </div>
                            <div>
                                در صورت عدم اعلام نرخ در وب سایت پیشروپی  از قبول سفارش جهت خدمات  معذور می باشیم.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                26.
                            </div>
                            <div>
                                پیشروپی  از ارائه خدمات به سایتهای مرتبط با شرط بندی وقمار، تبلیغات کلیکی وخرید وی پی ان معذور می باشد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                27.
                            </div>
                            <div>
                                پیشروپی  خرید و فروش ارز دیجیتالی از اکانت های نامعتبر  خودداری می نماید.
                            </div>

                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                28.
                            </div>
                            <div>
                                تراکنش های وب سایت پیشروپی  تا این لحظه بدون مالیات می باشد و کارمزد اضافه می باشد.
                            </div>

                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                29.
                            </div>
                            <div>
                                پیشروپی  از ارائه خدمات به افراد زیر 18 سال تمام معذور می باشد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                30.
                            </div>
                            <div>
                                پیشروپی  تا به حال و مطلقا هیچ گونه همکاری با موسسه یا وبسایت ثالث دیگری نداشته و خارج از چهارچوب قوانین قادر به ارائه خدمات نمی باشد
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                31.
                            </div>
                            <div>
                                در مورد خرید با پی پال و یا شارژ اکانت سایت بواسطه پی پال یا مسترکارت برای مبالغ بالای یکصد دلار و نیز اکانت هایی که با آی پی ایران تابحال لاگین نشده و یا آدرس و مشخصات مربوط به ایران داخل اکانت ثبت نشده باشد میسر خواهد بود.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                32.
                            </div>
                            <div>
                                درصورتیکه اعتبار اکانت بواسطه پی پال شارژ شود و سپس بعلت نقض یکی از موارد ذکر شده در ماده ۳۱ قوانین ریفاند و یا بلاک شدن وجه انجام پذیرد پیشروپی  هیچگونه مسئولیتی در قبال عودت وجه ریالی به مشتری برعهده نخواهد گرفت.
                            </div>

                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                33.
                            </div>
                            <div>
                                چنانچه کاربری حساب کاربری خود را با تمام اطلاعات رمز بانکی و غیره به شخص دیگری به اجاره واگذار نماید و شخص یا اشخاص دیگری با استفاده از حساب کاربری و کارت بانکی وی اقدام به خرید ارز نمایند، تمامی عواقب کیفری این کار اعم از پولشویی، سرقت ریال، کلاهبرداری، فیشینگ، خارج کردن غیرقانونی ارز از کشور و غیره بر عهده شخصی خواهد بود که حساب کاربری خود را در سایت به تائید رسانده است. پیشروپی  در اینگونه مواقع می تواند بر علیه کاربر خاطی در مراجع قانونی و قضایی، انتظامی تنظیم شکایت نماید.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                34.
                            </div>
                            <div>
                                قیمت های خرید از سایت، متناسب با فاکتور شما تنها تا 30 ثانیه معتبر خواهند بود و ممکن است پس از اتمام مهلت پرداخت، دچار نوسانات قیمتی شوید.(پیشنهاد می نماییم اول کیف پول خود را شارژ و سپس با اعتبار ریالی آن خرید نمایید)
                            </div>
                    </div>
                        <div className="d-inline-flex">

                            <div className="text-secondary">
                                35.
                            </div>
                            <div>
                                مسئولیت نادرست بودن مشخصات کیف پول مقصد با کاربر بوده و خدمات انتقال رمزارزها، اتوماتیک و آنی و غیرقابل بازگشت می باشند.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                36.
                            </div>
                            <div>
                                طبق مقررات و زیرساخت های فنی شبکه الکترونیکی پرداخت کارت کشور (شاپرک) پرداخت از طریق درگاه های واسط (پراخت یار) همراه با مبلغی به عنوان کارمزد می باشد، که پرداخت آن بر عهده پرداخت کننده (کاربر) می باشد.
                            </div>

                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                37.
                            </div>
                            <div>
                                در برخی از رمز ارزها و شبکه های انتقال، نیاز به درج تگ یا ممو TAG/MEMO وجود دارد، کاربر ملزم است آن ها را به دقت وارد نماید، در غیر این صورت دارایی خود را از دست خواهد داد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                38.
                            </div>
                            <div>
                                مقدار انتقال رمزارز در هنگام فروش به سایت باید دقیقا مطابق با مبلغ ذکر شده در فاکتور باشد، در غیر اینصورت تایید تراکنش به 24 ساعت آینده موکول خواهد شد.(حتما پس از کسر کارمزد انتقال شبکه مقدار انتقالی خالص را با فاکتور تطبیق دهید)
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                39.
                            </div>
                            <div>
                                انتقال رمزارز به سایت باید پس از ایجاد فاکتور و به آدرس مشخص شده و در شبکه مذکور انجام شود، در غیر اینصورت ضمن احتمال از بین رفتن رمزارز انتقالی، تایید تراکنش شما ممکن است تا 24 ساعت به طول انجامد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                40.
                            </div>
                            <div>
                                کاربر می بایست از انتقال رمزارزهای دیگر به این کیف پول خودداری نماید، در غیر اینصورت ضمن احتمال بالای از بین رفتن دارایی ایشان، تایید آن نیز ممکن است تا 24 ساعت به طول بیانجامد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                41.
                            </div>
                            <div>
                                قیمت های فروش به سایت، متناسب با فاکتور شما تنها تا 30 ثانیه معتبر خواهند بود و ممکن است پس از اتمام کانفرم های مورد نیاز شبکه، دچار نوسانات قیمتی شوید. ملاک محاسبه مبلغ قابل نقد شوندگی به ریال، همان قیمت لحظه کانفرم شدن کامل ارز منتقل شده توسط کاربر، در کیف پول سایت خواهد بود.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                42.
                            </div>
                            <div>
                                در پاره ای موارد مانند تناقض مبلغ، رمزارز و یا یکسان بودن شناسه تراکنش با فاکتوری دیگر، کاربر موظف است در صورت درخواست پشتیبانی سایت، مالکیت کیف پول مبدا را احراز نماید و در صورت عدم توانایی در قضاوت، مبلغ واریزی با کسر کارمزد شبکه به آدرس مبدا برگشت داده خواهد شد، لذا از واریز رمزارز از طریق کیف پول های تحت مالکیت اشخاص دیگر خودداری فرمایید.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                43.
                            </div>
                            <div>
                                کاربر باید از اعمال کارمزد های نامتعارف و ناچیز برای انتقال رمزارز پرهیز بفرماید، در این صورت ممکن است دارایی ایشان تا یک ماه با تاخیر به کیف پول مقصد برسد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                44.
                            </div>
                            <div>
                                درخواست های برداشت ریالی ثبت شده، روزانه دو بار به واسطه حواله پایا انجام می شوند، اگر قبل از ساعت 11:00 ثبت کرده اید، مبلغ ریالی تا ساعت 14:30 همان روز (اگر روز تعطیل رسمی نباشد) به حسابتان واریز خواهد شد، اگر بین ساعات 11:00 الی 23:00 ثبت کرده اید، مبلغ ریالی تا ساعت 11:30 صبح روز بعد غیر تعطیل رسمی به حسابتان واریز خواهد شد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                45.
                            </div>
                            <div>
                                کارمزد حواله پایا و ساتنا از اصل مبلغ درخواست برداشت وجه کاربر کسر خواهد شد، کارمزدهای متعارف شبکه بانکی کشور برعهده کاربر می باشد.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                46.
                            </div>
                            <div>
                                کارمزدهای انتقال رمزارزها همواره در تغییر بوده و پرداخت کارمزد انتقال شبکه بلاکچین بر عهده کاربر بوده و کارمزد انتقال سایر ارزها به غیر از رمزارزها بر عهده سایت خواهد بود.
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <div className="text-secondary">
                                47.
                            </div>
                            <div>
                                کاربر موظف می باشد همواره قوانین سایت را کنترل نموده و هرگونه تغییر ایجاد شده در قوانین که طی فعالیت در سایت لحاظ می شود کاربر را متعهد به راعیت و پذیرش آنها می نماید.
                            </div>
                        </div>

                    </DialogContentText>
                </DialogContent>
                </>

        )
    }
}


export default WebsiteRulesDialog;
